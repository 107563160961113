import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

import ColorPicker from '../../helpers/ColorPicker';
import Container from '../Container';
import Icon from '../Icon';
import Richtext from '../../helpers/Richtext';

//

const ProcessHorizontal = ({ content }) => {
  const theme = ColorPicker(content.BgColor, false);
  const sliderRef = useRef(null);

  function handleHover(e) {
    const allChildrenArr = [...sliderRef.current.children];
    const activeChildren = allChildrenArr.find(
      (el) => el.className === 'active'
    );

    if (activeChildren) {
      activeChildren.classList.remove('active');
    }
    e.currentTarget.parentNode.classList.add('active');
  }

  const Slide = ({ content, middle }) => (
    <li className={middle ? 'active' : undefined}>
      <button
        className={`process__slide ${middle && 'middle'}`}
        type="button"
        onMouseOver={(e) => handleHover(e)}
        onFocus={(e) => handleHover(e)}
        onClick={(e) => handleHover(e)}
      >
        <div>
          <div className="icon">
            {content.Icon && <Icon shape={content.Icon} />}
          </div>

          <h3>{content.Title}</h3>

          {content.Copy && (
            <div
              className="copy"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: Richtext(content.Copy) }}
            />
          )}
        </div>

        <div>
          <div className="diamond" />
          {middle && <hr />}
        </div>
      </button>
    </li>
  );

  return (
    <SbEditable content={content}>
      <ProcessHorizontalWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
        contrast={
          theme.background.includes('--3') || theme.background === 'white'
        }
      >
        <ProcessHorizontalContainer>
          <ul className="process__slider" ref={sliderRef}>
            {content.Pills &&
              content.Pills.map((pill, index) => (
                <Slide key={pill._uid} content={pill} middle={index === 1} />
              ))}
          </ul>
        </ProcessHorizontalContainer>
      </ProcessHorizontalWrap>
    </SbEditable>
  );
};

export default ProcessHorizontal;

const ProcessHorizontalContainer = styled(Container)`
  width: 100%;
  padding: 0;
`;

const ProcessHorizontalWrap = styled.section`
  padding: 4rem;
  background-color: var(--${(props) => props.themeBackground});

  --gap: 3.2rem;

  @media (min-width: 640px) {
    padding: 6rem;
  }

  @media (min-width: 768px) {
    --gap: 6.4rem;
  }

  .icon {
    display: flex;
    justify-content: center;
    color: var(--${(props) => props.themeText});
  }

  .process__slider {
    display: grid;
    grid-gap: var(--gap);
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0;
    list-style: none;

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1024px) {
      grid-gap: var(--gap);
    }

    .process__slide {
      display: flex;
      position: relative;
      z-index: 15;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      background: transparent;

      &.middle {
        z-index: 10;
      }

      svg {
        fill: var(--${(props) => props.themeText});
      }

      h3 {
        margin: 1.6rem 0;
        color: var(--${(props) => props.themeText});
        font-family: var(--font-serif);
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 3.4rem;

        @media (min-width: 1024px) {
          font-size: 4.2rem;
          line-height: 4.4rem;
        }
      }

      .copy {
        font-size: 1.4rem;
        line-height: 2rem;

        p {
          margin-bottom: 1.2rem;
          color: var(--${(props) => props.themeText});

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        a {
          text-decoration: underline;
        }

        @media (min-width: 768px) {
          margin-bottom: 2.4rem;
        }
      }

      .diamond {
        display: none;

        @media (min-width: 768px) {
          display: block;
          position: relative;
          z-index: 5;
          width: 0.9rem;
          height: 0.9rem;
          transform: rotate(45deg);
          transform-origin: center;
          transition: var(--transition);
          background-color: var(
            --${(props) => (props.contrast ? props.themeDivider : props.themeText)}
          );
        }
      }

      hr {
        display: none;

        @media (min-width: 768px) {
          display: unset;
          position: absolute;
          z-index: 0;
          bottom: -0.6rem;
          width: calc(200% + var(--gap) * 2);
          height: 0.1rem;
          transform: translateX(-50%);
          border: 0;
          background-color: var(
            --${(props) => (props.contrast ? props.themeDivider : props.themeText)}
          );
        }
      }
    }

    li {
      position: relative;

      --transition: 500ms ease all;

      @media (min-width: 768px) {
        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 5;
          top: -1.6rem;
          left: -1.6rem;
          width: 100%;
          height: 100%;
          padding: 1.6rem;
          transition: var(--transition);
          border-radius: 22rem;
          opacity: 0;
          background-color: ${(props) =>
            props.contrast
              ? 'rgba(255, 255, 255, 0.5)'
              : `var(--${props.themeDivider})`};
          pointer-events: none;

          @media (min-width: 1024px) {
            top: -3.2rem;
            left: -3.2rem;
            padding: 3.2rem;
          }
        }
      }

      &.active {
        .diamond {
          transform: rotate(45deg) scale(1.5);
          transition: var(--transition);
          background-color: var(--${(props) => props.themeText});
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

ProcessHorizontal.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    Icon: PropTypes.string,
    Title: PropTypes.string,
    Copy: PropTypes.string,
    Pills: PropTypes.array,
  }),
};

ProcessHorizontal.defaultProps = {
  content: {
    BgColor: 'white',
    Icon: 'SingleNeutralShield',
    Title: null,
    Copy: null,
    Pills: null,
  },
};
