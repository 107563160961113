import StoryblokClient from 'storyblok-js-client';
import sanitizeHtml from 'sanitize-html';

//

export default function Richtext(content) {
  const Storyblok = new StoryblokClient({
    accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
  });

  function cleanHtml(dirty) {
    return sanitizeHtml(dirty, {
      allowedTags: ['p', 'a', 'br'],
      allowedAttributes: {
        a: ['href', 'target'],
      },
    });
  }

  return cleanHtml(Storyblok.richTextResolver.render(content));
}
