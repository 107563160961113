import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Alert from '../../assets/icons/alert.inline.svg';
import Bars from '../../assets/icons/bars.inline.svg';
import Burger from '../../assets/icons/burger.inline.svg';
import BusinessCash from '../../assets/icons/business-cash.inline.svg';
import Camera from '../../assets/icons/camera.inline.svg';
import CashDocument from '../../assets/icons/cash-document.inline.svg';
import Cloud from '../../assets/icons/cloud.inline.svg';
import Cog from '../../assets/icons/cog.inline.svg';
import Cogs from '../../assets/icons/cogs.inline.svg';
import CurrencyConvert from '../../assets/icons/currency-convert.inline.svg';
import Data from '../../assets/icons/data.inline.svg';
import DateTime from '../../assets/icons/date-time.inline.svg';
import Date from '../../assets/icons/date.inline.svg';
import Devices from '../../assets/icons/devices.inline.svg';
import DocumentTime from '../../assets/icons/document-time.inline.svg';
import Down from '../../assets/icons/down.inline.svg';
import Download from '../../assets/icons/download.inline.svg';
import DrawTimeDocument from '../../assets/icons/draw-time-document.inline.svg';
import Filter from '../../assets/icons/filter.inline.svg';
import FlowNonLinear from '../../assets/icons/flow-non-linear.inline.svg';
import Flow from '../../assets/icons/flow.inline.svg';
import Gallery from '../../assets/icons/gallery.inline.svg';
import Global from '../../assets/icons/global.inline.svg';
import Grid from '../../assets/icons/grid.inline.svg';
import Group from '../../assets/icons/group.inline.svg';
import Handshake from '../../assets/icons/handshake.inline.svg';
import Hide from '../../assets/icons/hide.inline.svg';
import Home from '../../assets/icons/home.inline.svg';
import Key from '../../assets/icons/key.inline.svg';
import Left from '../../assets/icons/left.inline.svg';
import Link from '../../assets/icons/link.inline.svg';
import List from '../../assets/icons/list.inline.svg';
import Lock from '../../assets/icons/lock.inline.svg';
import MarkedSheet from '../../assets/icons/marked-sheet.inline.svg';
import MoneyTimeDocument from '../../assets/icons/money-time-document.inline.svg';
import NewDocument from '../../assets/icons/new-document.inline.svg';
import Open from '../../assets/icons/open.inline.svg';
import Paste from '../../assets/icons/paste.inline.svg';
import Pencil from '../../assets/icons/pencil.inline.svg';
import Print from '../../assets/icons/print.inline.svg';
import Question from '../../assets/icons/question.inline.svg';
import Right from '../../assets/icons/right.inline.svg';
import StackLock from '../../assets/icons/stack-lock.inline.svg';
import Stack from '../../assets/icons/stack.inline.svg';
import Tap from '../../assets/icons/tap.inline.svg';
import Tick from '../../assets/icons/tick.inline.svg';
import TimeDocument from '../../assets/icons/time-document.inline.svg';
import Trash from '../../assets/icons/trash.inline.svg';
import Up from '../../assets/icons/up.inline.svg';
import Upgrade from '../../assets/icons/upgrade.inline.svg';
import User from '../../assets/icons/user.inline.svg';
import Warn from '../../assets/icons/warn.inline.svg';
import Zoom from '../../assets/icons/zoom.inline.svg';

//

const Icon = ({ shape }) => {
  if (!shape) return null;

  return (
    <IconWrap aria-hidden="true" className="icon">
      {/* Delete these eventually v */}
      {shape === 'SingleNeutralShield' && <Alert />}
      {shape === 'TimeStopwatch3Quarters' && <Alert />}
      {shape === 'MultipleNeutralCircle' && <Alert />}
      {/* Delete these eventually ^ */}

      {shape === 'Alert' && <Alert />}
      {shape === 'Bars' && <Bars />}
      {shape === 'Burger' && <Burger />}
      {shape === 'BusinessCash' && <BusinessCash />}
      {shape === 'Camera' && <Camera />}
      {shape === 'CashDocument' && <CashDocument />}
      {shape === 'Cloud' && <Cloud />}
      {shape === 'Cog' && <Cog />}
      {shape === 'Cogs' && <Cogs />}
      {shape === 'CurrencyConvert' && <CurrencyConvert />}
      {shape === 'Data' && <Data />}
      {shape === 'DateTime' && <DateTime />}
      {shape === 'Date' && <Date />}
      {shape === 'Devices' && <Devices />}
      {shape === 'DocumentTime' && <DocumentTime />}
      {shape === 'Down' && <Down />}
      {shape === 'Download' && <Download />}
      {shape === 'DrawTimeDocument' && <DrawTimeDocument />}
      {shape === 'Filter' && <Filter />}
      {shape === 'FlowNonLinear' && <FlowNonLinear />}
      {shape === 'Flow' && <Flow />}
      {shape === 'Gallery' && <Gallery />}
      {shape === 'Global' && <Global />}
      {shape === 'Grid' && <Grid />}
      {shape === 'Group' && <Group />}
      {shape === 'Handshake' && <Handshake />}
      {shape === 'Hide' && <Hide />}
      {shape === 'Home' && <Home />}
      {shape === 'Key' && <Key />}
      {shape === 'Left' && <Left />}
      {shape === 'Link' && <Link />}
      {shape === 'List' && <List />}
      {shape === 'Lock' && <Lock />}
      {shape === 'MarkedSheet' && <MarkedSheet />}
      {shape === 'MoneyTimeDocument' && <MoneyTimeDocument />}
      {shape === 'NewDocument' && <NewDocument />}
      {shape === 'Open' && <Open />}
      {shape === 'Paste' && <Paste />}
      {shape === 'Pencil' && <Pencil />}
      {shape === 'Print' && <Print />}
      {shape === 'Question' && <Question />}
      {shape === 'Right' && <Right />}
      {shape === 'StackLock' && <StackLock />}
      {shape === 'Stack' && <Stack />}
      {shape === 'Tap' && <Tap />}
      {shape === 'Tick' && <Tick />}
      {shape === 'TimeDocument' && <TimeDocument />}
      {shape === 'Trash' && <Trash />}
      {shape === 'Up' && <Up />}
      {shape === 'Upgrade' && <Upgrade />}
      {shape === 'User' && <User />}
      {shape === 'Warn' && <Warn />}
      {shape === 'Zoom' && <Zoom />}
    </IconWrap>
  );
};

export default Icon;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  min-width: 1.8rem;
  max-width: 1.8rem;

  svg {
    width: 100%;
  }
`;

Icon.propTypes = {
  shape: PropTypes.string,
};

Icon.defualtProps = {
  shape: null,
};
