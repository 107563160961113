export default function ColorPicker(color, alt) {
  let background = null;
  let divider = null;
  let text = null;
  let highlight = alt;

  switch (color) {
    case 'white':
      background = 'white';
      divider = 'grey--2';
      text = 'grey--0';
      highlight = text;
      break;

    case 'black':
      background = 'black';
      divider = 'grey--2';
      text = 'white';
      highlight = text;
      break;

    case 'grey--3':
      background = 'grey--3';
      divider = 'grey--2';
      text = 'grey--0';
      highlight = text;
      break;

    case 'red--0':
      background = 'red--0';
      divider = 'red--1';
      text = 'white';
      highlight = !alt ? text : 'red--2';
      break;

    case 'red--3':
      background = 'red--3';
      divider = 'white';
      text = 'red--0';
      highlight = !alt ? text : 'red--2';
      break;

    case 'green--0':
      background = 'green--0';
      divider = 'green--1';
      text = 'white';
      highlight = !alt ? text : 'green--2';
      break;

    case 'green--3':
      background = 'green--3';
      divider = 'white';
      text = 'green--0';
      highlight = !alt ? text : 'green--2';
      break;

    case 'blue--0':
      background = 'blue--0';
      divider = 'blue--1';
      text = 'white';
      highlight = !alt ? text : 'blue--2';
      break;

    case 'blue--3':
      background = 'blue--3';
      divider = 'white';
      text = 'blue--0';
      highlight = !alt ? text : 'blue--2';
      break;

    case 'orange--0':
      background = 'orange--0';
      divider = 'orange--1';
      text = 'white';
      highlight = !alt ? text : 'orange--2';
      break;

    case 'orange--3':
      background = 'orange--3';
      divider = 'white';
      text = 'orange--0';
      highlight = !alt ? text : 'orange--2';
      break;

    case 'earth--0':
      background = 'earth--0';
      divider = 'earth--1';
      text = 'white';
      highlight = !alt ? text : 'earth--2';
      break;

    case 'earth--3':
      background = 'earth--3';
      divider = 'white';
      text = 'earth--1';
      highlight = !alt ? text : 'grey--0';
      break;

    case 'transparent':
      background = 'transparent';
      divider = 'black';
      text = 'white';
      highlight = !alt ? text : 'black';
      break;

    default:
      background = 'grey--0';
      divider = 'black';
      text = 'white';
      highlight = !alt ? text : 'grey--2';
  }

  return { background, divider, text, highlight };
}
